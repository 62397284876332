<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("appointments.onlineDates") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"> GRAFIC </v-card-text>
    <v-data-table
      v-else
      :headers="headersAppointmentsOnlineDates"
      :items="list"
      :items-per-page="5"
      id="appointmentsOnlineDates-table"
    >
      <template v-slot:item.cantidad="{ item }">
        {{ item.cantidad }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "AppointmentsAdvice",
  components: {},
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.onlineDates;
    },
  },
  data() {
    return {
      graphic: true,
      headersAppointmentsOnlineDates: [
        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
    };
  },
};
</script>
